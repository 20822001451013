html {
  height: 100%;
  overflow-x: hidden !important;
}

body {
  font-family: 'Inter', sans-serif !important;
  font-size: 18px !important;
  color: #ffffff !important;
  margin: 0 !important;
  padding: 0 !important;
  background-color: #121216 !important;
  overflow-x: hidden !important;
  -ms-overflow-style: none;
}

body ::-webkit-scrollbar {
  display: none;
}

.bigStageTop2Like {
  padding-right: 0px !important;
  max-width: 5.333333% !important;
  margin-top: 3px;
}

.bigStagesLike {
  max-width: 5.333333% !important;
  margin-top: 3px;
}

.collapse.show {
  display: block;
  background: black;
  border-radius: 10px;
}

.btnPadding {
  padding: 10px 20px !important;
}

.moreinfo-follow-row {
  margin-right: 0px !important;
}

.pad-0 {
  padding: 0;
}

.custom-logo {
  height: 100px !important;
}

.custom-header-fix {
  background-color: transparent;
  padding: 0;
}

.news-event-card-img {
  height: 159px;
  width: 211px !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.banner {
  background-size: cover;
  background-repeat: no-repeat;
  height: 650px;
  padding: 0px 0;
  position: relative;
}

.videoModalDialog {
  width: 100% !important;
  height: 580px !important;
  max-width: none !important;
  margin-top: 100px !important;

}

.latest-video-container {
  border: 5px solid #181a20;
  margin-bottom: 20px;
}

.latest-video-images {
  height: 311px;
  width: 100%;
}

.latest-video-item-images {
  height: 300px;
  width: 100%;
}

.banner::before {
  content: "";
  position: absolute;
  background-color: #0000005c !important;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, #121216 8.98%, rgba(0, 0, 0, 0) 20%);
}

.home-coustom .featured-artists-title {
  margin: 0;
}

.custom-navbar {
  display: flex;
  align-items: center;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1.5rem;
}

.custom-navbar .nav-link {
  color: #b9b9b9 !important;
  font-size: 14px !important;
}

.nav-item active {
  color: white !important;
  font-size: 18px;
}

.navbar-brand {
  color: white !important;
}

.song-name {
  font-size: 30px;
  margin-bottom: 10px;
}

.play-icon {
  font-size: 40px;
  color: #ffd874;
}

.playlist {
  position: absolute;
  bottom: 100px;
}

.homeplaylist {
  margin: 290px 0 0 240px;
  position: relative;
}

.the-big-stage-title {
  font-size: 20px;
}

.the-big-stage {
  background-color: #121216;
  padding: 0 0 50px 0;
}

.owl-nav {
  position: absolute;
  top: -60px;
  right: 0;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  font-size: 45px;
  margin-left: 15px;
}

.big-stage-signer {
  font-size: 12px;
  color: #a198b4;
}

.featured-artists {
  background-color: #121216;
  padding: 0 0 50px 0;
}

.upcoming-events {
  background-color: #121216;
  padding: 0 0 50px 0;
}

.card-body {
  color: rgb(255, 255, 255);
  background-color: #121216 !important;
  padding: 10px 10px !important;
}

.event-card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 !important;
}

.card {
  border: 5px solid #181a20 !important;
  background-color: transparent !important;
}

.event-date {
  font-size: 12px;
  background-color: rgb(195 195 195);
  color: black;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 63px;
  right: 0;
  font-weight: 500;
  padding: 5px 10px;
}

.small-slider-btn {
  position: relative;
}

.small-slider-btn .owl-prev {
  position: absolute !important;
  left: -21px !important;
}

.small-slider-btn .owl-nav {
  position: absolute !important;
  top: -15px !important;
  right: 220px;
}

.small-slider-btn .owl-next {
  position: absolute !important;
  right: -250px !important;
}

.latest-news {
  background-color: #121216;
  padding: 0 0 50px 0;
}

.categories {
  background-color: #121216;
  padding: 0 0 50px 0;
}

.category-image {
  position: relative;
}

.category-img-text {
  position: absolute;
  color: white;
  font-size: 15px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categories .item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.podcasts {
  background-color: #121216;
  padding: 0 0 50px 0;
}

.footer {
  background-color: #121216;
  padding: 0 0 50px 0;
}

.footer-line {
  width: 100%;
  height: 0.5px;
  background-color: rgb(37, 37, 37);
}

.footer-menu {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.footer-menu a {
  color: white !important;
  font-size: 15px;
}

.footer-main-menu {
  margin-left: 30px;
}

.copyright {
  font-size: 13px;
  color: #a198b4;
  text-align: center;
  margin-top: 15px;
}

.footer-custom-logo {
  height: 100px !important;
}

.footer-logo {
  text-align: center;
  margin-top: 15px;
}

.banner-big-stage {
  background-size: cover;
  background-repeat: no-repeat;
  height: 650px;
  position: relative;
  padding: 0px 0;
}

.banner-big-stage::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0000005c !important;
  background: linear-gradient(359deg, #121216f2 8.98%, rgba(0, 0, 0, 0) 20%);
}

.big-stage-latest-video {
  background-color: #121216;
  padding: 50px 0;
}

.video-card:hover+.video-page-backdrop,
.video-page-backdrop:hover {
  visibility: visible;
}

.video-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.video-page-backdrop {
  visibility: hidden;
  transition: transform 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: white;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0px;
}

.hover-content {
  position: relative;
  z-index: 2;
}

.bg-color {
  background-color: black;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  bottom: 0px;
}

.play-icon-video {
  font-size: 30px;
  color: #ffd874;
  cursor: pointer;
}

.latest-video-main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* banner-coomunicate-room */

.comunicate-room-header {
  background-color: #121216;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100px;
  padding: 50px 0;
}

.share-btn-white {
  color: white;
}

.community-room-banner {
  background-color: #121216;
  padding: 130px 0 50px 0;
}

.event-wrapText fs-40 fw-700 {
  font-size: 47px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 500px 0px 0px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.community-home-card {
  width: 17rem !important;
}

.btn-primary {
  color: rgb(0, 0, 0) !important;
  background-image: linear-gradient(#ffd874, #d8a318) !important;
  border-color: #ffd874 !important;
  width: fit-content !important;
  padding: 8px 25px !important;
  margin-top: 10px !important;
  font-size: 15px !important;
  font-weight: 500;
  border-radius: 100px;
}

.custom-more-btn {
  width: 45px !important;
  height: 45px !important;
  border-radius: 100px !important;
  color: rgb(0, 0, 0) !important;
  background-image: linear-gradient(#ffd874, #d8a318) !important;
  margin-top: 9px;
}

.dark {
  color: #ffd874 !important;
  width: fit-content !important;
  padding: 8px 25px !important;
  margin-top: 10px !important;
  font-size: 15px !important;
  font-weight: 500;
  background-color: rgb(83, 83, 83) !important;
  margin-bottom: 20px !important;
  border-radius: 100px;
}



.btn-primary-event {
  color: rgb(0, 0, 0) !important;
  /*hitendra*/
  background-image: linear-gradient(#ffd874, #d8a318) !important;
  /*hitendra*/
  border-color: #ffd874 !important;
  /*hitendra*/
  width: fit-content !important;
  /*hitendra*/
  max-width: 125px !important;
  /*hitendra*/
  min-width: 125px !important;
  /*hitendra*/
  margin-top: 10px !important;
  /*hitendra*/
  font-size: 12px !important;
  /*hitendra*/
}

/* banner-coomunicate-room */

.upload-section {
  background-color: #121216;
  padding: 30px;
}

.upload-box {
  background-color: #181a20b0;
  padding: 20px;
  display: flex;
  align-items: center;
  color: #e6e2ef;
}

.upload-here {
  padding-left: 35px;
  color: #e6e2ef;
}

.upload-box-contant {
  font-size: 12px;
}

.upload-wave {
  width: 466px;
  padding: 0 13px;
}

.btn-primary-2 {
  color: rgb(0, 0, 0);
  background-image: linear-gradient(#ffd874, #d8a318);
  border-color: #ffb700;
  min-width: 120px;
  max-width: 120px;
  margin-top: 10px;
  font-size: 12px;
}

.btn-dark {
  color: #ffd874 !important;
  background-color: rgb(83, 83, 83) !important;
  border-color: rgb(83, 83, 83) !important;
  width: fit-content !important;
  padding: 8px 25px !important;
  margin-top: 10px !important;
  font-size: 15px !important;
  font-weight: 500;
  border-radius: 100px;
}



.upload-section-singer-name {
  background-color: #121216;
  padding: 0 0 30px 0;
}

.likes {
  margin-left: 6px;
  font-size: 15px;
}

.like-icon {
  display: flex;
  justify-content: space-between;
}

.event-date-community {
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.418);
  color: black;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 128px;
  right: 0;
  padding: 5px 10px;
}

.navbar-light .navbar-toggler-icon {
  background-image: url(../assets/Images/menu.svg) !important;
  /*hitendra*/
}

/* featured-artist */

.featured-image-annimation {
  margin-top: 0px;
}

.featured-image-annimation:hover {
  margin-top: -5px;
  transition: 0.5s;
}

.artist {
  background-color: #121216;
  padding: 0 0 50px 0;
}

.search-btn {
  padding: 3px 7px;
  background-color: rgb(83, 83, 83);
  border-radius: 5px;
}

.artist-slider {
  padding: 3px 7px;
  background-color: rgb(68, 68, 68) !;
  border-radius: 5px;
  color: #ffd874;
  margin-left: 15px;
  cursor: pointer;
}

.artist-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.artist-image-annimation {
  margin-top: 0px;
  max-width: 139px;
  max-height: 140px;
  min-height: 140px;
  object-fit: fill;
}

.artist-image-annimation:hover {
  margin-top: -7px;
  transition: 0.5s;
}

.add-dialouge .modal-dialog {
  max-width: 900px;
  margin: 6.75rem auto;
}

.custom-add-playlist {
  color: #a198b4;
  font-size: 15px;
}

.custom-song-add {
  /* margin-left: 58px; */
  margin-top: 25px;
}

.playlist-player-name {
  font-size: 12px;
  margin-top: 10px;
}

.cancel-btn {
  border: 1px solid #ffd874;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #ffd874;
  border-radius: 4px;
  min-width: 100%;
  background-color: transparent;
  cursor: pointer;
  margin: 15px 0;
}

.add-to-playlist-btn {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #202020;
  border-radius: 4px;
  min-width: 100%;
  background-image: linear-gradient(#ffd874, #d8a318);
  cursor: pointer;
  border: 1px solid #ffd874;
  margin: 15px 0;
}

.custom-playlist-pad {
  padding: 0 15px;
}

/* featured-artist-2 */

.feature-banner-2 {
  /* background-image: url(../assets/Images/salena.png); */
  background-color: #121216;
  background-size: cover;
  background-repeat: no-repeat;
  height: 650px;
  padding: 0px 0;
  background-position: top;
  position: relative;
}

.feature-banner-2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: #0000005c !important; */
  background: linear-gradient(360deg, #121216 8.98%, rgba(0, 0, 0, 0) 20%);
  /* background: transparent linear-gradient(
      180deg, #121216 0%, #21242b00 0%, #121216 95%) 0% 0% no-repeat padding-box; */
}

.selena-gomez-about {
  font-size: 13px;
}

.custom-play-icon {
  padding: 5px 25px !important;
  /*hitendra*/
}

.btn-like {
  padding: 6px 9px !important;
  /*hitendra*/
  background-color: #747474 !important;
  font-size: 17px;
  margin-top: 7px;
  border-radius: 100px;
  color: #ffd874;
  cursor: pointer;
  min-width: 35px;
}

.dropdown-toggle::after {
  display: none;
}

.btn-share {
  padding: 6px 9px !important;
  /*hitendra*/
  background-color: #747474 !important;
  /*hitendra*/
  font-size: 17px !important;
  /*hitendra*/
  margin-top: 10px !important;
  /*hitendra*/
  border-radius: 4px !important;
  /*hitendra*/
  color: white !important;
  /*hitendra*/
  cursor: pointer !important;
  /*hitendra*/
}

.custom-pad {
  padding: 0;
}

.play-btn {
  padding-left: 10px;
}

.album {
  background-color: #121216;
  padding: 50px 0;
}

.title-header {
  color: #a198b4;
  font-size: 15px;
}

/* .song-table {
    background-color: #181A20B0;
    padding: 20px 30px;
} */
.song-table {
  background-color: #181a20b0;
  padding: 20px 0;
  margin-bottom: 10px;
}

/* .song-number {
    color: #e2e2e2;
    font-size: 13px;
}

.songs-name {
    color: #e2e2e2;
    font-size: 13px;
    margin-left: 82px;
} */
.song-number {
  color: #e2e2e2;
  font-size: 13px;
}

.songs-name {
  color: #e2e2e2;
  font-size: 13px;
}

#bars {
  height: 30px;
  margin: 0;
  width: 15px;
}

.bar {
  background: #ffd874;
  bottom: 1px;
  height: 3px;
  position: absolute;
  width: 3px;
  animation: sound 0ms -800ms linear infinite alternate;
}

@keyframes sound {
  0% {
    opacity: 0.35;
    height: 3px;
  }

  100% {
    opacity: 1;
    height: 28px;
  }
}

.bar:nth-child(1) {
  left: 1px;
  animation-duration: 474ms;
}

.bar:nth-child(2) {
  left: 5px;
  animation-duration: 433ms;
}

.bar:nth-child(3) {
  left: 9px;
  animation-duration: 407ms;
}

.bar:nth-child(4) {
  left: 13px;
  animation-duration: 458ms;
}

.bar:nth-child(5) {
  left: 17px;
  animation-duration: 400ms;
}

.bar:nth-child(6) {
  left: 21px;
  animation-duration: 427ms;
}

.bar:nth-child(7) {
  left: 25px;
  animation-duration: 441ms;
}

.bar:nth-child(8) {
  left: 29px;
  animation-duration: 419ms;
}

.bar:nth-child(9) {
  left: 33px;
  animation-duration: 487ms;
}

.bar:nth-child(10) {
  left: 37px;
  animation-duration: 442ms;
}

.podcast-details-page {
  position: absolute;
  bottom: 100px;
}

.songs-name-active {
  color: #e2e2e2;
  font-size: 13px;
  margin-left: 77px;
}

.album-name {
  color: #e2e2e2;
  font-size: 13px;
}

.song-time {
  color: #e2e2e2;
  font-size: 13px;
}

.song-detail {
  color: white;
  font-size: 13px;
  margin-left: 18px;
}

.song-like {
  color: #dfad2a;
  margin-left: 18px;
}

.play-song {
  font-size: 25px;
  color: #dfad2a;
}

/* .song-detail {
    color: white;
    font-size: 13px;
    margin-left: 18px;
} */

/* .song-like {
    color: #FFD874;
    margin-left: 18px;
} */

.song-table-active {
  background-color: #31343d;
  padding: 20px 30px;
}

/* .play-song {
    font-size: 18px;
    color: #FFD874;
} */

.mix-tape {
  background-color: #121216;
  padding: 50px;
}

.modal-content {
  background-color: #313131;
  padding: 10px;
  border: none !important;
}

.comment-dialog-sep {
  height: calc(250px - 10px);
  overflow-y: scroll;
}

.modal-open .modal {
  overflow-y: hidden;
}

.modal-dialog {
  /* max-width: 450px; */
  margin: 3rem auto;
}

.comment-dialouge .modal-dialog {
  max-width: 1100px !important;
  height: calc(100% - 100px);
  overflow-y: hidden;
  overflow-x: hidden;
}

.modal-header {
  border: 0;
}

.modal-header .close {
  color: yellow;
  font-size: 28px;
  font-weight: 400;
}

.max-text {
  width: 150px;
}

.icon-yellow {
  color: #d3ba34;
}

.custom-share-btn {
  color: white !important;
  font-size: 40px !important;
  padding-bottom: 50px;
}

.dropdown-arrow-hide .dropdown-toggle::after {
  display: none;
}

.dropdown-arrow-hide .dropdown-item {
  font-size: 12px !important;
  color: #a198b4;
}

.dropdown-arrow-hide .dropdown-menu {
  background-color: #1f1f1f;
}

.share-btn-color {
  color: white;
}


/* more info */

.btn-hip-hop {
  color: #ffffff;
  width: fit-content;
  padding: 5px 15px;
  margin-top: 10px;
  font-size: 12px;
  background-color: #747474 !important;
}

.custom-play-icon-2 {
  padding: 10px 35px !important;
  /*hitendra*/
  margin-top: 25px !important;
  /*hitendra*/
}

.btn-share-more {
  padding: 7px 13px !important;
  /*hitendra*/
  background-color: #474747 !important;
  color: #ffd874 !important;
  /*hitendra*/
  font-size: 17px !important;
  /*hitendra*/
  margin-top: 10px !important;
  /*hitendra*/
  border-radius: 4px !important;
  /*hitendra*/
  cursor: pointer !important;
  /*hitendra*/
  margin-top: 25px !important;
  /*hitendra*/
}

.dark-btn {
  background-color: #474747 !important;
  color: #ffd874 !important;
}



.moreinfo-detail {
  background-color: #121216;
  padding: 50px 0;
}

.mt-0 {
  margin-top: 0;
}

.w100 {
  width: 100% !important;
}

.w50 {
  width: 50% !important;
}

.w70 {
  width: 70% !important;
}

.biogrphy-detail {
  font-size: 13px;
  color: #747474;
  margin-top: 15px;
}

.location-btn {
  padding: 3px 5px;
  color: #ffd874;
  background-color: #747474;
  border-radius: 3px;
}

.location-btn-2 {
  padding: 2px 5px;
  color: #ffd874;
  background-color: #747474;
  border-radius: 3px;
}

.social-link-gap {
  margin-left: 50px;
}

.social-link {
  margin-top: 25px;
}

.news-box {
  border: 1px solid #747474;
  padding: 10px 50px 10px 15px;
  margin-top: 15px;
}

.location-text {
  color: #747474;
  font-size: 13px;
}

.album-release-year {
  font-size: 13px;
  color: #747474;
  margin-top: 5px;
}

.add-playlist2 {
  padding-left: 15px;
  color: #a198b4;
  font-size: 15px;
}

.username {
  margin-top: 0px;
  /* padding: 0 15px; */
  /*hitendra*/
}

/****  floating-Lable style start ****/

.floating-label {
  position: relative;
  margin-bottom: 10px;
}

.floating-input,
.floating-select {
  font-size: 14px;
  padding: 9px 4px;
  display: block;
  width: 100%;
  color: white;
  background-color: #181a20;
  border: none;
  border: 1px solid #757575;
  text-indent: 9px;
  border-radius: 5px;
}

.floating-select1 {
  color: rgb(165, 165, 165) !important;
}

.floating-input:focus,
.floating-select:focus {
  outline: none;
}

.label {
  color: #999;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 13px;
  top: 11px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.floating-input:focus~label,
.floating-input:not(:placeholder-shown)~label {
  top: -10px;
  font-size: 12px;
  color: white;
  background-color: #181a20;
}

.floating-select:focus~label,
.floating-select:not([value=""]):valid~label {
  top: -18px;
  font-size: 14px;
  color: #5264ae;
}

/* active state */

.floating-input:focus~.bar:before,
.floating-input:focus~.bar:after,
.floating-select:focus~.bar:before,
.floating-select:focus~.bar:after {
  width: 50%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.floating-textarea {
  min-height: 30px;
  max-height: 260px;
  overflow-y: hidden;
  overflow-x: hidden;
}

/* highlighter */

.floating-label .highlight {
  position: absolute;
  height: 50%;
  width: 100%;
  top: 15%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */

.floating-input:focus~.highlight,
.floating-select:focus~.highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* animation */

@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

/****  floating-Lable style end ****/
/***   Body style start  ***/

html {
  font-family: "Helvetica Neue", Helvetica, "Noto Sans", sans-serif, Arial, sans-serif !important;
  /*hitendra*/
  font-size: 12px;
  line-height: 1.42857143;
  color: #949494;
  background-color: #ffffff;
}

/***   Body style end  ***/
/***   daniel - Fork me friend - style   ***/

.floating-credit {
  position: fixed;
  bottom: 10px;
  right: 10px;
  color: #aaa;
  font-size: 13px;
  font-family: arial, sans-serif !important;
  /*hitendra*/
}

.floating-credit a {
  text-decoration: none;
  color: #000000;
  font-weight: bold;
}

.floating-credit a:hover {
  border-bottom: 1px dotted #f8f8f8;
}

.floating-heading {
  position: fixed;
  color: #aaa;
  font-size: 20px;
  font-family: arial, sans-serif !important;
  /*hitendra*/
}

.login-area {
  padding: 70px 100px;
}

.forget-password a {
  color: #ffffff !important;
  font-size: 13px;
}

.form-check-label {
  color: #ffffff !important;
  font-size: 13px;
  margin-bottom: 0;
  padding-left: 5px;
}

.form-check-input {
  margin-top: 2px;
}

.custom-control-label::before {
  background-color: transparent;
  border: 1px solid white;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #d8a318;
  border: 1px solid #d8a318;
}

.btn-color {
  background-image: linear-gradient(#ffd874, #d8a318);
  color: #262931;
  font-size: 15px;
  margin-top: 20px;
  border-bottom-color: #d8a318;
  border-radius: 5px !important;
}

.option-text {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #ffffff;
}

.custom-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 10px 20px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  cursor: pointer;
  width: 100%;
}

.custom-btn-color {
  background: linear-gradient(180deg, #ffffff3b 0%, #ffffff1c 100%) 0% 0% no-repeat padding-box;
  color: #ffd874;
  font-size: 15px;
  margin-top: 20px;
}

/* Forget-page */

.Forget-page {
  padding: 150px 100px;
}

/* otp-verification */

.verrification-box .form-control {
  margin-right: 0px !important;
  /*hitendra*/
}

.form-control {
  margin-right: 12px;
  background-color: transparent;
  border: 1px solid #757575;
  padding: 0.375rem 1rem;
}

.form-control:focus {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff;
  outline: 0;
  box-shadow: none;
}

.cursor {
  cursor: pointer;
}

.form-control {
  margin-right: 80px;
}

.resend-text {
  font-size: 18px;
  font-weight: bold;
  color: #ffd874;
  cursor: pointer;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 30px;
  padding-left: 30px;
}

/* create an aacount */

.create-an-account {
  margin-top: 0px;
}

.selection-page {
  border: 1px solid #757575;
  padding: 8px 4px;
  border-radius: 5px;
  padding-left: 13px;
  padding-top: 11px;
  color: #999;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
}

.selection-page-2 {
  border: 1px solid #757575;
  padding: 8px 4px;
  border-radius: 5px;
  padding-left: 13px;
  padding-top: 11px;
  color: #999;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.terms-hihglight {
  color: #ffd874;
}

.login-highligh {
  font-size: 14px;
  color: #ffd874;
}

.create-an-account-page {
  padding: 50px 100px;
}

/* otp-verification-2 */

.otp-verification-highlight {
  font-size: 16px;
  color: #e6e2ef;
}

/* complete-profile */

/* .complete-profile {
  margin-top: 10px;
} */
/* complete-profile-2 */

.complete-profile-2 {
  margin-top: 10px;
}

.add-account {
  border: 1px solid #ffd874;
  padding: 2px 5px;
  color: #ffd874;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.carousel-indicators {
  bottom: -35px;
}

.custom-playlist {
  padding: 15px;
}

/* podcast */

.podcast-banner {
  min-width: 100%;
  max-height: 100%;
}

.featured-episodes {
  background-color: #121216;
  padding: 0 0 50px 0;
}

.feature-episode-box {
  padding: 10px;
  background-color: #181a20b0;
}

.episode-img {
  height: 48px;
  min-width: 50px;
  max-width: 50px;
  border-radius: 30px;
  object-fit: cover;
}

.episode-title {
  font-size: 14px;
  overflow: hidden;
}

.episode-sub-title {
  font-size: 10px;
  padding: 10px 0 0 10px;
}

.epi-share {
  margin-left: -10px;
}

.song-discription {
  font-size: 13px;
  color: #999;
  line-height: 17px;
  font-weight: 500;
}

.eipsode-btn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: rgb(99, 99, 99);
  margin-left: -30px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #ffd874;
  cursor: pointer;
}

/* podcast-categories */

.podcast-categories {
  background-color: #121216;
  padding: 0 0 50px 0;
}

.pod-category-img {
  min-width: 160px;
  max-width: 160px;
  height: 100px;
  position: relative;
}

.pod-category-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 5px 5px 5px black;
  font-size: 14px;
  text-align: center;
}

.podcast-banner-2 {
  /* background-image: url(../assets/Images/podcast2banner.png); */
  background-size: cover;
  background-repeat: no-repeat;
  height: 755px;
  padding: 0px 0;
  background-position: top;
  position: relative;
}

.podcast-banner-2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(360deg, #121216 5%, #21242b75 46%, #121216 100%) 0% 0% no-repeat padding-box;
}

.podcast-banner-image {
  min-width: 300px;
  max-width: 350px;

}

.podcast-playlist-namne {
  font-size: 50px;
  margin: 320px 0 0 0;
}

.episode {
  background-color: #121216;
  padding: 50px 0;
}

.comments {
  background-color: #121216;
  padding: 0 0 50px 0;
}

.comment-main {
  padding: 0;
  /* background-color: #121216; */
}

.comment-main-podcast {
  padding: 0;
  background-color: #121216 !important;
}

.comment-date {
  font-size: 10px;
  color: #999;
  padding-top: 5px;
  padding-left: 20px;
  text-align: end;
}

.feature-episode-box-2 {
  padding: 15px 10px;
  border-bottom: 1px solid rgb(58, 58, 58);
}

/* upload-music */

.upload-music-background {
  background-color: #181a20;
  padding: 50px;
}

.upload-background {
  border: 1px dotted grey;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.upload-background1 {
  border: 1px dotted grey;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.upload-image-icon {
  min-width: 70px;
  max-width: 70px;
}

.upload-image-icon-2 {
  max-width: px;
}

.upload-text-subtitle {
  font-size: 16px;
  margin-top: 10px;
}

.upload-text {
  margin-top: 20px;
}

.upload-text-subtitle-2 {
  font-size: 10px;
  margin-top: 20px;
}

.add-fav-subtitle {
  font-size: 10px;
  margin: 20px 0;
  text-align: center;
  color: #a198b4;
}

.login-sub-title {
  font-size: 13px;
  color: #a198b4;
}

.mt-10 {
  margin-top: 10px;
}

.uppload-music-right-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.upload-btn {
  padding: 8px 60px;
}

.drag-and-download {
  min-height: 470px;
}

.download-music-box {
  padding: 10px 20px;
  background-color: #121216;
}

.close-btn {
  min-width: 15px;
  max-width: 15px;
}

.downloading-line {
  width: 262px;
  height: 2px;
  background-image: linear-gradient(to right, #ffd874, grey);
  padding: 0;
}

.download-size {
  font-size: 10px;
}

.download-title {
  font-size: 12px;
  padding: 0;
}

.downoading-percent {
  font-size: 10px;
}

/* newz */


.master-news .event-date {
  bottom: 92px;
}

.more-newz {
  background-color: #121216;
  padding: 100px 0 0px 0;
}


.more-news-date {
  font-size: 12px;
  margin-top: 20px;
}

.newz-artist-name {
  font-size: 40px;
  margin-top: 15px;
}

.newz-title {
  font-size: 40px;
  margin-top: 15px;
}

.newz-work {
  font-size: 12px;
  margin-top: 15px;
}

.morenewz-banner {
  /* width: 100%;
  height: 450px; */
  margin-top: 30px;
}

.more-news-detail {
  background-color: #121216;
  padding: 30px 0;
}

.P-highlight {
  font-size: 55px;
}

.new-paregraph {
  padding: 0;
  margin-top: 15px;
  margin-left: -25px;
}

.more-newz-line {
  width: 100%;
  height: 1px;
  background-color: rgb(58, 58, 58);
}

.news-text {
  font-size: 12px;
  color: #999;
  margin-top: 25px;
}

.status-name {
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}

.news-highlight-paregraph {
  margin-top: 25px;
}

.left-line {
  background-color: #ffd874;
  width: 1px;
  height: 90px;
}

.quotation-mark {
  max-width: 40px;
}

.review-images {
  max-width: 30px;
  border-radius: 30px;
  object-fit: cover;
}

.review-name {
  font-size: 12px;
  color: #999;
}

.review-icon {
  font-size: 15px;
  color: #ffd874;
}

.review-name {
  margin-left: 10px;
}

.end {
  justify-content: end;
}

.view-more {
  margin-right: 5px;
}

/* events */

.events-banner {
  background-image: url(../assets/Images/eventsBanner.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
  padding: 100px 0;
  background-position: top;
  position: relative;
}

.events-detail-banner {
  /* background-image: url(../assets/Images/eventsbanner2.png); */
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
  padding: 0px 0;
  position: relative;
  background-position: top;
  margin-bottom: 50px;
}

.events-detail-banner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0000005c !important;
  background: linear-gradient(360deg, #121216 8.98%, rgba(0, 0, 0, 0) 100%);
}

.events-detail-banner .container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}




.calender {
  min-width: 260px;
  background-image: linear-gradient(to right, #454e66b0, #9c9b9b57);
  padding: 15px;
  max-width: 250px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.calender-mobile {
  min-width: 260px;
  background-image: linear-gradient(to right, #454e66b0, #9c9b9b57);
  padding: 15px;
  max-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.choose-date {
  background-color: transparent !important;
  border: 0;
  font-size: 12px;
  color: #ffd874 !important;
  padding: 5px 0;
}

.calender.label {
  display: block;
}

.calender.input label {
  margin: 0.4rem 0;
}

.map {
  max-width: 300px;
}

.tags-btn {
  padding: 7px 10px;
  background-color: #000000b0;
  color: #e6e2ef;
  font-size: 12px;
  border-radius: 5px;
}

.tags-programe {
  display: flex;
}

.event-upload-page {
  border: 1px dotted grey;
  padding: 75px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.event-upload-page1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.eventupload {
  margin-top: -40px;
}

/* profile */

.profile-banner {
  background-color: #121216;
  padding: 100px 0;
}

.profile-box-left {
  background-color: #181a20;
  padding: 20px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;

  max-height: 553px;
  min-height: 553px;
}

.profile-main {
  position: relative;
  margin-top: 10px;
}

.profile-main-image {
  max-width: 150px;
  min-width: 150px;
}

.profile-follower {
  background-color: #121216;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-playlist {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.profile-playlist:hover {
  color: grey;
  text-decoration: none;
}

.profile-notification-text {
  color: #888888;
  font-size: 15px;
}

.playlist-title {
  color: #747474;
  font-size: 13px;
}

.playlist-title-2 {
  color: #747474;
  font-size: 30px;
}

.premium-btn {
  background-image: linear-gradient(to right, #3a3a3a, #2b2b2b);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #ffd874;
  border-radius: 4px;
  min-width: 281px;
  cursor: pointer;
  margin: 15px 0;
  border: 0;
}

.profile-box-right {
  background-color: #181a20;
  padding: 20px 20px 0 20px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-height: 553px;
  min-height: 553px;
}

.edit-profile-icon {
  height: 30px;
  width: 30px;
  border-radius: 50px;
  color: white;
  background-image: linear-gradient(#ffd874, #d8a318);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  position: absolute;
  bottom: 0;
  right: 43%;
  box-shadow: 10px 10px 10px rgb(29 29 29);
}

.tabset>input[type="radio"] {
  position: absolute;
  left: -200vw;
}

.tabset .tab-panel {
  display: none;
}

.tabset>input:first-child:checked~.tab-panels>.tab-panel:first-child,
.tabset>input:nth-child(3):checked~.tab-panels>.tab-panel:nth-child(2),
.tabset>input:nth-child(5):checked~.tab-panels>.tab-panel:nth-child(3),
.tabset>input:nth-child(7):checked~.tab-panels>.tab-panel:nth-child(4),
.tabset>input:nth-child(9):checked~.tab-panels>.tab-panel:nth-child(5),
.tabset>input:nth-child(11):checked~.tab-panels>.tab-panel:nth-child(6) {
  display: block;
}

.tabset>label {
  position: relative;
  display: inline-block;
  padding: 11px 62px 15px;
  /* padding: 15px 62px 25px; */
  border: 1px solid transparent;
  border-bottom: 0;
  cursor: pointer;
  color: #a198b4;
}

.tabset>label:hover,
.tabset>input:focus+label {
  color: #ffffff;
}

.tabset>input:checked+label {
  border-bottom: 1px solid #ffd874;
  margin-bottom: -1px;
}

.tab-panel {
  padding: 18px 0;
}

/*
Demo purposes only
*/

*,
*:before,
*:after {
  box-sizing: border-box;
}

.tabset {
  max-width: 65em;
}

.profile-center {
  margin-top: 10px;
  height: 380px;
  overflow-y: scroll, hide scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.profile-center-abooutus {
  margin-top: 10px;
  height: 350px;
  overflow-y: scroll, hide scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.profile-right-page {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px;
  box-sizing: content-box;
}

.profile-notification {
  background-color: #121216;
  padding: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-color: #1e2025;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-image: linear-gradient(#ffd874, #d8a318);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.profile-setting {
  background-color: #121216;
  padding: 10px;
}

.change-password-button {
  background-image: linear-gradient(#ffd874, #d8a318);
  padding: 10px 30px;
  color: #202020;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  border: 0;
}

.audio-subtitle {
  font-size: 13px;
}

.profile-setting .floating-label {
  margin-bottom: 0;
}

.profile-setting .floating-input,
.floating-select {
  padding: 4px 4px;
}

#forgot-pass {
  width: 100%;
  height: 100%;
}

.carousel-inner>.item>img {
  width: 1200px;
  height: 360px !important;
}

#forgot-pass1 {
  display: none;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 452px;
  background-color: #181a20;
  padding: 20px;
}

.mt-50 {
  margin-top: 185px;
}

.follower-box {
  background-color: #121216;
  padding: 10px;
}

.follower-box-img {
  min-width: 50px;
  max-width: 50px;
}

.follow-btn {
  padding: 5px;
  background-image: linear-gradient(#ffd874, #d8a318);
  color: #202020;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  min-width: 30px;
  min-height: 10px;
}

.following-btn {
  padding: 5px;
  color: #ffd874;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #ffd874;
}

.follow-btn:hover {
  color: #202020;
  text-decoration: none;
}

.following-btn:hover {
  color: #ffd874;
  text-decoration: none;
}

/* myplaylist */

.my-playlist {
  background-color: #121216;
  padding: 125px 0 50px 0;
}

/* favourite-music */

.favourite-music-add {
  background-color: #121216;
  padding: 50px 0;
}

.favourite-song {
  /* background-image: url(../assets/Images/favourite-song-banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
  padding: 100px 0;
  background-position: top; */
  background-size: cover;
  background-repeat: no-repeat;
  height: 790px;
  padding: 0px 0;
  position: relative;
  background-position: top;
}

.favourite-song::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(90deg, #121216 5%, #21242b75 46%, #121216 100%) 0% 0% no-repeat padding-box;
}

.favourite-right-box-image {
  min-width: 300px;
  max-width: 425px;
  /* max-width: 350px; */
  margin-top: 45px;
  margin-left: 100px;
}

.add-favorite {
  padding: 85px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.add-to-fav-banner {
  background-color: #121216;
  padding: 50px 0 0px 0;
}

.add-fav-upoad {
  margin-top: 15px;
}

.add-fav-song-title {
  font-size: 18px;
  color: white;
}

.add-favourite-song-tab {
  height: 380px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.favourite-song-tab {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 20px;
  box-sizing: content-box;
}

.podcast-left-box {
  height: 333px;
  overflow-y: scroll, hide scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.podcast-left-box .downloading-line {
  width: 248px;
}

.mypodcast-middle {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px;
  box-sizing: content-box;
}

.custom-add-news .card-img-top {
  max-height: 130px;
  min-width: 236px;
  max-width: 236px;
  object-fit: cover;
}

.custom-add-news .card-title {
  font-size: 13px;
}

.custom-add-news .event-date {
  bottom: 74px;
}

.carousel-inner>.item>img {
  width: 1200px;
  height: 360px !important;
}

.login-page {
  background-color: #121216;
  padding: 0px 0;
}

.login-bg img {
  height: 100vh;
  width: 100%;
  margin-left: -15px;
}

.title {
  font-size: 40px;
  color: #e6e2ef;
}

.mt-20 {
  margin-top: 20px;
}

/****  floating-Lable style start ****/

.floating-label {
  position: relative;
}

.floating-input,
.floating-select {
  font-size: 14px;
  padding: 9px 4px;
  display: block;
  width: 100%;
  color: white;
  background-color: transparent;
  border: none;
  border: 1px solid #757575;
  text-indent: 9px;
  border-radius: 5px;
}

.floating-select1 {
  color: rgb(165, 165, 165) !important;
}

.floating-input:focus,
.floating-select:focus {
  outline: none;
}

.label {
  color: #999;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 13px;
  top: 11px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.floating-input:focus~label,
.floating-input:not(:placeholder-shown)~label {
  top: -10px;
  font-size: 12px;
  color: white;
  backdrop-filter: blur(1px);
  padding: 0px 6px;
  background-color: transparent;
  /* satyam changed this 09/08/2021 */
  /* background-color: black;  */
}

.floating-select:focus~label,
.floating-select:not([value=""]):valid~label {
  top: -18px;
  font-size: 14px;
  color: #5264ae;
}

/* active state */

.floating-input:focus~.bar:before,
.floating-input:focus~.bar:after,
.floating-select:focus~.bar:before,
.floating-select:focus~.bar:after {
  width: 50%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.floating-textarea {
  min-height: 30px;
  max-height: 260px;
  overflow: hidden;
  overflow-x: hidden;
}

/* highlighter */

/*Hitendra*/
.floating-label .highlight {
  position: absolute;
  height: 50%;
  width: 100%;
  top: 15%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */

.floating-input:focus~.highlight,
.floating-select:focus~.highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* animation */

@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

/****  floating-Lable style end ****/
/***   Body style start  ***/

html {
  font-family: "Helvetica Neue", Helvetica, "Noto Sans", sans-serif, Arial, sans-serif !important;
  /*hitendra*/
  font-size: 12px;
  line-height: 1.42857143;
  color: #949494;
  background-color: #ffffff;
}

/***   Body style end  ***/
/***   daniel - Fork me friend - style   ***/

.floating-credit {
  position: fixed;
  bottom: 10px;
  right: 10px;
  color: #aaa;
  font-size: 13px;
  font-family: arial, sans-serif !important;
  /*hitendra*/
}

.floating-credit a {
  text-decoration: none;
  color: #000000;
  font-weight: bold;
}

.floating-credit a:hover {
  border-bottom: 1px dotted #f8f8f8;
}

.floating-heading {
  position: fixed;
  color: #aaa;
  font-size: 20px;
  font-family: arial, sans-serif !important;
  /*hitendra*/
}

.login-area {
  padding: 70px 100px;
}

.forget-password a {
  color: #ffffff !important;
  font-size: 13px;
}

.form-check-label {
  color: #ffffff !important;
  font-size: 13px;
  margin-bottom: 0;
  padding-left: 5px;
}

.form-check-input {
  margin-top: 2px;
}

/* .custom-control-label::before {
    background-color: transparent;
    border: 1px solid white;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #D8A318;
    border: 1px solid #D8A318;
} */

/* .btn {
  padding: 10px 20px;
} */

.btn-color {
  background-image: linear-gradient(#ffd874, #d8a318) !important;
  color: #262931 !important;
  font-size: 15px !important;
  margin-top: 20px !important;
  border-bottom-color: #49453b !important;
  border-radius: 5px !important;
}

.option-text {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #ffffff;
}

.custom-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 10px 20px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  cursor: pointer;
  width: 100%;
}

.custom-btn-color {
  background: linear-gradient(180deg, #ffffff3b 0%, #ffffff1c 100%) 0% 0% no-repeat padding-box;
  color: #ffd874;
  font-size: 15px;
  margin-top: 20px;
}

/* Forget-page */

.Forget-page {
  padding: 150px 100px;
}

/* otp-verification */

.verrification-box .form-control {
  margin-right: 0px;
}

.form-control {
  margin-right: 12px !important;
  background-color: transparent !important;
  border: 1px solid #757575 !important;
  padding: 0.375rem 1rem !important;
}

.form-control:focus {
  color: #ffffff !important;
  background-color: transparent !important;
  border-color: #ffffff !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.cursor {
  cursor: pointer;
}

.form-control {
  margin-right: 80px !important;
}

.resend-text {
  font-size: 18px;
  font-weight: bold;
  color: #ffd874;
  cursor: pointer;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
  /* padding-right: 30px;
  padding-left: 30px; */
}

/* create an aacount */

.create-an-account {
  margin-top: 0px;
}

.selection-page {
  border: 1px solid #757575;
  padding: 8px 4px;
  border-radius: 5px;
  padding-left: 13px;
  padding-top: 11px;
  color: #999;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
}

.selection-page-2 {
  border: 1px solid #757575;
  padding: 8px 4px;
  border-radius: 5px;
  padding-left: 13px;
  padding-top: 11px;
  color: #999;
  font-size: 14px;
  display: flex;
  justify-content: space-between;

  margin-bottom: 5px;
}

.terms-hihglight {
  color: #ffd874;
}

.login-highligh {
  font-size: 14px;
  color: #ffd874;
}

.create-an-account-page {
  padding: 50px 100px;
}

/* otp-verification-2 */

.otp-verification-highlight {
  font-size: 16px;
  color: #e6e2ef;
}

/* complete-profile */

/* .complete-profile {
  margin-top: 10px;
} */
/* complete-profile-2 */

.complete-profile-2 {
  margin-top: 10px;
}

.add-account {
  border: 1px solid #ffd874;
  padding: 2px 5px;
  color: #ffd874;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.carousel-indicators {
  bottom: -35px;
}

/* Dharmik */
img {
  object-fit: cover !important;
  object-position: top !important;
}

/*Hitendra*/
.navbar-light .navbar-nav .nav-link {
  color: #b9b9b9 !important;
}

#multiselectTypes button {
  background-color: black !important;
  color: white !important;
  font-size: 14px !important;
  border-color: transparent !important;
  border: 1px solid #757575 !important;
}

#multiselectTypes label {
  color: black !important;
}

.social-media {
  color: white;
  font-size: 17px;
}

.rememberMe,
.iagreewith,
.didntreceiveotp {
  color: #ffffff !important;
  font-size: 13px;
}

.nav {
  display: flex !important;
  flex-wrap: unset !important;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
  list-style: none !important;
}

.progress-bar {
  background-color: #121216 !important;
}

.fixed-top {
  position: absolute !important;
  /*hitendra*/
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

/* .artist-like{
    padding: 6px 9px !important;
} */
.react-jinke-music-player .music-player {
  top: 130px !important;
}

.react-jinke-music-player-mobile {
  background-color: rgba(0, 0, 0, 1.75) !important;
  bottom: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  /* padding: 20px; */
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  padding: 20px 27px 20px 0px !important;
  /* top: 110px !important; */
  /* padding-bottom: 20px !important;
    padding-right: 27px !important;
    padding-top: 20px !important; */
}

/* .react-jinke-music-player-main .music-player-panel {
    background-color: rgba(0,0,0,1.75) !important;
    bottom: 0;
    box-shadow: 0 0 3px #403f3f;
    color: #fff;
    height: 80px;
    left: 0;
    position: fixed;
    transition: background-color .35s cubic-bezier(.43,-.1,.16,1.1);
    width: 100%;
    z-index: 99;
} */

.artistMore {
  /* width: 23px !important;
    height: 21px !important;
    margin-top: -9px; */
  padding: 0px !important;
}

.fileContainer {
  background: none !important;
  padding: 0 !important;
  margin: 0px !important;
  width: 100% !important;
  height: 100% !important;
}

.chooseFileButton {
  background-image: linear-gradient(#ffd874, #d8a318) !important;

  border-color: #ffd874 !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 5px !important;
}

.errorMessage {
  color: red;
  font-size: 12px;
  letter-spacing: 0.8px;
}

.fileUploader {
  width: 100%;
  height: 100% !important;
}

.edit-profile-upload {
  padding: 0px;
  margin: 147px 8px 0px 0px;
  height: 30px !important;
  width: 30px !important;
  border-radius: 50px !important;
  color: white !important;
  /* background-image: linear-gradient(#FFD874, #D8A318) !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  position: absolute;
  /* bottom: 0; */
  right: 43%;
  box-shadow: 10px 10px 10px rgb(29 29 29);
}

.profile-main .fileContainer .chooseFileButton {
  /* added By Satyam */
  backdrop-filter: blur(1px);
  background: transparent !important;
  top: -41px;
  position: absolute;
  /* satyam end */
  padding: 0px;
  /* margin: 147px 8px 0px 0px; */
  height: 30px !important;
  width: 30px !important;
  border-radius: 50px !important;
  color: white !important;
  /* background-image: linear-gradient(#FFD874, #D8A318) !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  position: absolute;
  bottom: 0;
  right: 43%;
  box-shadow: 10px 10px 10px rgb(29 29 29);
}

.navbar-collapse {
  /* flex-basis: 100% !important; */
  flex-grow: 0 !important;
  align-items: center !important;
}

.events-text {
  padding-top: 0px;
}

.uploadFileButton {
  width: 120px;
  height: 30px;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffd874;
  border-radius: 5px;
  color: #ffd874;
  background: transparent;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  /* margin-top: 0px !important; */
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: "";
  display: inline-block;
  position: absolute !important;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  color: transparent;
}

.custom-file-input:hover::before {
  content: "";
}

.custom-file-input:active::before {
  content: "";
}

.react-datepicker-wrapper {
  width: 100% !important;
  border: 1px solid #757575 !important;
  background-color: #181a20 !important;
  border-radius: 4px;
}

.react-datepicker__input-container input {
  width: 100%;
  border: 0px !important;
  background-color: #181a20;
  color: white;
  font-size: 15px;
  height: 46px;
  padding: 10px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
  display: none !important;
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
  display: none !important;
  bottom: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  display: none !important;
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  display: none !important;
  top: 0;
}

.react-datepicker {
  font-size: 1rem !important;
}

.wrapText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.centerTextInDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-file-input {
  position: absolute !important;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
  margin-top: 0px !important;
  cursor: pointer;
}

.marginTop0 {
  margin-top: 0px !important;
}

.progress_bar1 {
  width: 1rem;
  height: -1rem;
  position: relative;
  right: 20px;
  bottom: 20px;
}

.progress_bar {
  width: 4rem;
  height: 4rem;
  position: relative;
  right: 20px;
  bottom: 20px;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: orange !important;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: white !important;
  font-size: 20px;
  font-weight: 400;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: white !important;
}

.search-cont>div {
  background: transparent linear-gradient(180deg, #ffffff3b 0%, #ffffff1c 100%) 0% 0% no-repeat padding-box;
  border-radius: 7px;
  backdrop-filter: blur(25px);
  height: 29px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.search-cont div img {
  height: 15px;
  width: 15px;
}

#search-input {
  height: 30px;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 7px;
  cursor: initial;
  transition: 0.2s ease-in-out;
}

#search-input input {
  background: transparent;
  color: #ffffff;
  font-family: Mr Eaves Mod OT;
  font-weight: 400;
  font-size: 14px;
  height: 20px;
  width: 90px;
  outline: none;
  border: none;
  margin-left: 5px;
}

#search-input span {
  color: #e6e2ef;
  position: absolute;
  position: absolute;
  right: 10px;
  bottom: 2px;
  font-size: 21px;
  cursor: pointer;
  font-family: Mr Eaves Mod OT;
  font-weight: 500;
}

.search-main-cont {
  margin: 15px;
}

.notFound {
  color: black;
}

.eye-icon-show-hide {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  color: #ffd874;
}

.no-data-found {
  font-size: 14px;
  color: #ffd874;
  text-align: center;
  background-color: transparent;
  border: none !important;
}

/* profile tabs */
.divTabs .nav-tabs {
  border-bottom: none !important;
}

.divTabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  padding: 11px 62px 15px;
}

.divTabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffffff !important;
  border-bottom: 1px solid !important;
  background-color: transparent !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: #ffd874 !important;
}

.divTabs .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.divTabs .nav-tabs .nav-link {
  margin-bottom: -1px;
  /* border: 1px solid transparent; */
  border-top-left-radius: 0.25rem;
  /* border-top-right-radius: .25rem; */
}

.divTabs .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  color: #ffffff !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: #ffd874 !important;
}

.divTabs a {
  color: #a198b4 !important;
  text-decoration: none;
  background-color: transparent;
}

.profile-box-right a {
  color: #a198b4 !important;
  text-decoration: none;
  background-color: transparent;
}

.navbar-light .navbar-nav .active {
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

/* form-check-input > input[type="radio"]:checked + *::before {
  background: radial-gradient(
    rgb(0, 128, 32) 0%,
    rgb(0, 128, 32) 40%,
    transparent 50%,
    transparent
  );
  border-color: rgb(0, 128, 32);
}
form-check-input > input[type="radio"]:checked + * {
  color: rgb(0, 128, 32);
} */

.multiselect-close-icon {
  margin-left: 5px;
  /* color: red; */
  color: #ffd874;
  /* opacity: 0.9; */
}

/* .head-active{
  color: red;
} */

/* profile tabs */
.highlightOption {
  background: #fcc63c !important;
  /* color: #fff !important; */
}

.multiSelectContainer li:hover {
  background: #ffd874 !important;
  /* color: #fff !important; */
  cursor: pointer;
}

.custom-loader {
  /* background-color:tra */
  background-color: transparent !important;
  text-align: center;
}

.albumActive {
  /* border: 10px solid #181a20; */
  border: 3px solid #f7cd64;
  padding: 5px;
  /* margin-left: -20px; */
}

.wd239 {
  /* width: 239px; */
  /* width: 220px; */
  width: 234px;
}

.wd260 {
  width: 260px;
}

.playlistActive {
  border: 3px solid #f7cd64;
  padding: 5px;
}

.podcastDescription {
  font-size: 13px;
  color: #747474;
  margin-top: 15px;
}

.stickHeader {
  position: fixed !important;
  background-color: #121216 !important;
}

#divProfileImageUpload .errorsContainer {
  position: absolute;
  width: 400px !important;
  right: -111px;
  top: -18px;
}

#divProfileImageUpload1 .errorsContainer {
  position: absolute;
  width: 400px !important;
  right: -111px;
  top: 21px;
}

.songlist-page #bars {
  margin: 0;
}

.songlist-page .play-song {
  font-size: 25px;
  color: #ffd874;
}

.songlist-page {
  padding: 0 !important;
}

.song-list {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 20px;
  box-sizing: content-box;
}

.songlist-right-box {
  max-height: 450px;
  overflow-x: hidden;
  scroll-behavior: smooth;
  overflow-y: auto;
}

.cts-dro-si .dropdown-menu {
  right: -33px !important;
}

.cts-dro-si .btn {
  padding: 0 !important;
}

.myAlbumDetailsImg {
  width: 100%;
  height: 70vh;
}

.multiSelectContainer {
  margin-bottom: 20px;
}

.searchWrapper {
  border: 1px solid #757575 !important;
  border-radius: 5px !important;
}

.multiSelectContainer input {
  color: #fff;
}

.newItemDiv {
  width: 225px;
}

.mm-card-bg {
  background-color: #121216 !important;
}

.mm-card-body {
  color: rgb(255, 255, 255);
  background-color: #121216 !important;
  padding: 0px 10px 10px !important;
}

.communityRoomArtist {
  cursor: "pointer";
  margin-bottom: 25px;

}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #e2b232 !important;
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: 0;
  left: 0px;
  position: relative;
  background-color: transparent;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid black;
}

input[type="radio"]:checked:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  top: 0;
  left: 0px;
  position: relative;
  background-color: #ffd877;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 4px solid #d8a318;
}

/* ////Dharmik//// */
.podcasts .mm-card-bg {
  min-height: 262px !important;
}

#tns8>.tns-item {
  font-size: 15px;
}

#tns2>.tns-item {
  padding-right: 10px;
  width: calc(24%) !important;
}

@media only screen and (min-width: 768px) and (max-width: 1367px) {

  .custom-add-news .event-date {
    bottom: 105px;
  }

  .more-newz {
    padding: 80px 0 0px 0;
  }

  .profile-follower {
    min-width: 65%;
  }

  .add-to-playlist-btn {
    min-width: 100%;
  }

  .tabset>label {
    padding: 15px 55px 25px;
  }

  .songs-name {
    margin-left: 19px;
  }

  .album-name {
    margin-left: 19px;
  }

  .song-time {
    margin-left: 19px;
  }

  .songs-name-active {
    margin-left: 19px;
  }

  .song-number {
    margin-right: 6px;
  }

  .custom-dropdown {
    top: 19px !important;
    left: -28px !important;
  }

  .cust-pad-add {
    padding: 0;
  }

  .cust-pad-add-2 {
    padding: 0 0 0 15px;
  }

  .custom-song-add {
    margin-left: 0;
  }


  .community-room-banner {
    padding: 130px 0 60px 0;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
    float: none;
    left: -30px;
  }

  .navbar-nav .dropdown-menu {
    background-color: black;
  }

  .dropdown-item {
    color: #b9b9b9;
  }

  .eipsode-btn {
    margin-left: -10px;
  }

  .podcast-playlist-namne {
    margin: 45px 0 0 0;
  }

  .podcast-banner-2 {
    height: 525px;
  }

  .comment {
    padding: 0 0 50px 0;
  }


  .master-news .event-date {
    bottom: 155px;
  }

  .downloading-line {
    width: 346px;
  }

  .drag-and-download {
    margin-left: 70px;
    padding-bottom: 30px;
  }

  .featured-artists-title {
    margin-top: 0px;
  }


  .custom-song-add .img-fluid {
    max-width: 160px;
  }

  .custom-add-news .card-img-top {
    min-height: 100px;
    max-height: 100px;
    min-width: 132px;
    max-width: 132px;
  }

  .mypodcast-coustom .featured-artists-title {
    margin-top: 0;
  }

  .podcast-left-box .downloading-line {
    width: 345px;
  }

  .coustom-follow-tab .tabset>label {
    padding: 10px 68px;
  }

  .the-big-stage {
    padding: 0 0 50px 0;
  }

  .featured-artists {
    padding: 0 0 50px 0;
  }

  .upcoming-events {
    padding: 0 0 50px 0;
  }
}

/*Mobile Device */
@media only screen and (max-width: 575px) {
  .p-sm-0 {
    padding: 0;
  }

  .banner-img {
    height: 500px !important;
    background-repeat: no-repeat;
  }

  .round-btn {
    border-radius: 100%;
    padding: 10px !important;

  }

  .moreinfo-detail {
    padding: 30px 0;
  }

  .d-mobile-none {
    display: none;
  }

  .artistMore .btn-Secondary {
    padding: 0 !important;
  }

  .comment-date {
    margin: 0 !important;
    padding: 0 !important;
  }

  .song-discription {
    padding-left: 10px;
  }

  .comment-dialouge .song-discription {
    padding-left: 10px;
  }

  .comment-dialouge .comment-date {
    margin: 0 !important;
    padding: 0 !important;
  }

  .modal-open .modal {
    overflow-y: auto;
  }

  .custom-logo {
    margin-left: 60px;
  }

  .userProfileModalDialog {
    max-width: 450px !important;
    margin: 150px 0px;
  }

  .fileContainer input {
    position: fixed !important;
  }

  .cus-login-btn {
    margin-top: 4px !important;
    /* margin-left: 15px; */
  }


  .newItemDiv {
    width: 100% !important;
  }



  .cust-playlist .wrapText {
    width: 250px;
  }

  .collapse.show {
    display: block;
    background: black;
    border-radius: 10px;
  }

  .login-page {
    /* height: 900px; */
    padding: 50px 0 60px 0;
  }

  .nav-link {
    padding: 15px !important;
  }

  /* .nav-link:active {
    color: red;
    background-color: purple;
  }
  li:hover {
    color: red;
  } */

  /* li:active {
    color: red !important;
  } */
  .nav-item {
    width: 100%;
    /* border-bottom: 1px solid #535353; */
    /*hitendra*/
  }

  .nav-items {
    width: 100%;
    border-bottom: 0px solid #535353;
  }

  .navbar-dark .navbar-nav .nav-link {
    padding: 0.8rem 1.5rem;
  }

  .navbar-light .navbar-toggler {
    font-size: 18px !important;
    line-height: 0 !important;
    border: 1px solid #ffd87499 !important;
    padding: 5px 11px !important;
    height: 40px;
    margin-top: 5px !important;
    width: 50px;

  }

  .playlist {
    position: absolute;
    bottom: 100px;
  }

  .homeplaylist {
    margin: 230px 0 0 25px;
    /*83px 0 0 25px;*/
    position: absolute;
  }

  .banner {
    height: 550px;
    background-position-x: center;
  }

  .favourite-song {
    height: 640px;
    background-position-x: center;
  }

  .bigStageTop2Like {
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-width: 100% !important;
    margin-top: 3px;
  }

  .bigStagesLike {
    /* padding-left: 0px !important; */
    /* padding-right: 0px !important; */
    /* max-width: 5.333333% !important; */
    margin-top: 3px;
  }



  .events-detail-banner {
    height: 550px;
    background-position-x: center;
    margin-bottom: 0;
  }



  .videoModalDialog {
    width: 99% !important;
    height: 200px !important;
    max-width: none !important;
    margin-top: 130px !important;
  }



  .navbar-light .navbar-toggler-icon {
    color: #ffb700 !important;
    width: 25px !important;
  }

  .footer-main-menu {
    margin-left: 0;
    float: left;
    width: 50%;
  }

  .footer-menu a {
    color: white !important;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
  }

  .footer-menu {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }

  .footer-menu {
    display: flow-root;
    justify-content: center;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
    float: none;
    background-color: black;
  }

  .dropdown-item {
    color: #b9b9b9;
  }

  .banner-big-stage {
    height: 550px;
    background-position-x: center;
  }

  .upload-box {
    display: block;
    align-items: center;
    color: #e6e2ef;
    text-align: center;
  }

  .custom-card {
    width: 20rem !important;
  }

  .upload-box {
    display: block;
  }

  .custom-header-fix {
    background-color: #000000b5;
    padding: 0 15px;
  }

  .songs-name {
    margin-left: 0;
  }



  .album {
    padding: 85px 0;
  }

  .album-name {
    margin-left: 0;
    margin: 15px 0;
  }

  .song-time {
    margin-left: 0;
  }

  .songs-name-active {
    margin-left: 0;
    position: relative;
  }

  .play-icon-position {
    position: absolute;
    right: 25px;
    /* right: 8px; */
    top: 30px;
    margin-left: 25px;
  }

  /* .play-icon-position {
        margin-left: 25px;
    } */
  .custom-add-playlist {
    padding: 0;
  }

  .custom-song-add {
    margin: 20px 0;
    padding: 0;
  }

  .playlist-namne {
    font-size: 27px;
  }

  .feature-banner-2 {
    height: 550px;
    /* padding: 75px 0; */
  }

  .play-song {
    font-size: 30px;
  }

  .biogrphy-detail {
    font-size: 11px;
  }

  .location-text {
    font-size: 11px;
  }

  .share-model-conent {
    margin-top: 200px;
  }



  .selena-gomez-about {
    font-size: 11px;
  }

  .fs-40 {
    font-size: 30px !important;
  }

  .event-date {
    bottom: 62px;
  }

  .eipsode-btn {
    margin-top: -20px;
    margin-left: -22px;
  }

  .episode-title {
    padding: 0 0 0 10px;
  }

  .episode-sub-title {
    padding: 5px 0 0 10px;
  }

  .upload-section {
    padding: 0 0 30px 0;
  }

  .podcast-playlist-namne {
    margin: 0;
    font-size: 29px;
  }

  .podcast-banner-2 {
    height: 550px;
  }

  .like-position {
    margin-left: -15px;
  }

  .comment-main {
    padding: 0;
  }

  .comment-date {
    margin-left: -99px;
    margin-top: 32px;
  }

  .latest-news {
    padding: 0 0 30px;
  }

  .upload-music-background {
    padding: 15px;
  }

  .upload-background {
    padding: 35px 0;
    margin-bottom: 30px;
  }

  .upload-text {
    margin-top: 15px;
  }

  .mt {
    margin-top: 10px;
  }

  .drag-and-download {
    max-height: 225px;
    min-height: 225px;
    margin-left: 40px;
  }

  .community-room-banner {
    padding: 100px 0 50px 0;
  }

  .downloading-line {
    width: 77px;
  }

  .uppload-music-right-box {
    margin-top: 10px;
  }


  .master-news .event-date {
    bottom: 77px;
  }



  .newz-title {
    font-size: 30px;
  }

  .newz-artist-name {
    font-size: 30px;
  }

  .new-paregraph {
    margin-left: 8px;
    font-size: 13px;
  }

  .left-line {
    height: 135px;
  }

  .more-news-date {
    margin: 0;
  }

  .newz-artist-name {
    margin: 0;
  }

  .newz-title {
    margin: 0;
  }

  .newz-work {
    margin: 0;
  }

  .events-banner {
    height: 475px;
  }




  .calender {
    /* bottom: 70px; */
    /* bottom: 11px; */
    bottom: -65px;
    right: 55px;
  }

  .custom-add-news .card-img-top {
    min-width: 310px;
    max-width: 310px;
    object-position: center;
  }

  .custom-add-news .event-date {
    bottom: 59px;
  }

  .playlist-player-name {
    margin-bottom: 10px;
  }

  .nav-dropdown-image {
    min-height: 60px;
    max-height: 60px;
    padding: 9px 5px 5px;
    max-width: 63px;
    min-width: 63px;
  }

  .the-big-stage {
    padding: 30px 0;
  }

  .featured-artists {
    padding: 0;
  }

  .upcoming-events {
    padding: 30px 0;
  }

  .podcasts {
    padding: 30px 0;
  }

  .tabset>label {
    padding: 10px;
    font-size: 14px;
  }

  .premium-btn {
    min-width: 260px;
  }

  .change-password-button {
    padding: 10px;
    font-size: 12px;
  }

  .profile-banner {
    padding: 100px 0 10px 0;
  }

  .profile-box-right {
    padding: 20px 10px 0 10px;
    max-height: 575px;
  }

  #forgot-pass1 {
    height: 484px;
  }

  .coustom-follow-tab .tabset>label {
    padding: 10px 54px;
    font-size: 14px;
  }

  .tab-panel {
    padding: 15px 0 30px 0;
  }

  .podcast-playlist-namne {
    margin-top: 250px;
  }

  /* .favourite-song {
    height: 325px;
  } */
  .podcast-left-box .downloading-line {
    width: 80px;
  }

  .my-playlist .featured-artists-title {
    margin: 0px;
  }

  .event-date-community {
    bottom: 108px;
  }

  .latest-video-images {
    height: 230px !important;
  }

  .news-event-card-img {
    width: 100% !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }


  .react-jinke-music-player-mobile {
    padding: 20px !important;
    z-index: 9999 !important;
  }

  .react-jinke-music-player-mobile-cover {
    animation: fromTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
    border: 5px solid rgba(0, 0, 0, 0.2);
    border-radius: 100%;
    box-shadow: 0 0 1px 3px rgb(0 0 0 / 10%);
    height: 225px !important;
    margin: 25px auto;
    overflow: hidden;
    transition: box-shadow, border 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
    width: 225px !important;
  }

  .react-jinke-music-player-mobile-cover .cover {
    object-fit: cover !important;
    width: 100%;
    height: 100% !important;
  }

  /*light theme*/
  .react-jinke-music-player-main.light-theme .react-jinke-music-player-mobile {
    background-color: #fff;
    color: #31c27c !important;
  }

  .react-jinke-music-player-main.light-theme .react-jinke-music-player-mobile-singer-name {
    color: #31c27c !important;
    transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  }

  .react-jinke-music-player-main.light-theme .react-jinke-music-player-mobile-singer-name:after,
  .react-jinke-music-player-main.light-theme .react-jinke-music-player-mobile-singer-name:before {
    background-color: #31c27c !important;
  }

  .react-jinke-music-player-main.light-theme .react-jinke-music-player-mobile .current-time,
  .react-jinke-music-player-main.light-theme .react-jinke-music-player-mobile .duration {
    color: #31c27c !important;
  }

  .react-jinke-music-player-main.light-theme .react-jinke-music-player-mobile .current-time,
  .react-jinke-music-player-main.light-theme .react-jinke-music-player-mobile .duration {
    color: #31c27c !important;
  }

  .react-jinke-music-player-main.light-theme .react-jinke-music-player-mobile-operation svg {
    color: #31c27c !important;
  }

  .react-jinke-music-player-main.light-theme .rc-slider-rail {
    background-color: #fff !important;
  }

  .react-jinke-music-player .music-player .destroy-btn {
    right: -15px;
    color: #31c27c !important;
  }

  /* .react-jinke-music-player-main.light-theme svg {
    color: hsla(0,0%,100%,.6) !important;
  } */

  .dropdown-menu {
    min-width: auto;
  }

  .dropdown-menu show {
    position: absolute;
    margin: 0px;
    transform: translate3d(-80px, 27px, 0px) !important;
    inset: 100% 0 auto auto !important;
  }

  .dropdown-menu[x-placement^="bottom"],
  .dropdown-menu[x-placement^="left"],
  .dropdown-menu[x-placement^="right"],
  .dropdown-menu[x-placement^="top"] {
    right: -30 !important;
    left: auto !important;
  }

  .cust-playlist .wrapText {
    width: 250px;
  }

  .myAlbumDetailsImg {
    height: 50vh;
  }


  .song-table {
    padding: 20px 15px !important;
  }
}

/*Mobile Device */
/* Mobile Device - Media-query */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .banner-big-stage {
    height: 500px;
  }

  .latest-video-images {
    height: 100%;
  }

  .latest-video-item-images {
    height: 100%;
  }

  .banner {
    height: 500px;
  }

  .banner-img {
    height: 500px;
  }

  .playlist-namne {
    font-size: 35px;
  }

  .login-area {
    padding: 100px 100px;
  }

  .login-sub-title {
    margin-top: 25px;

  }

  .footer-main-menu {
    margin: 0;
  }

  .podcast-playlist-namne {
    margin-top: 295px;
  }

  .feature-banner-2 {
    height: 500px;
  }



  .profile-center {
    height: 380px;
  }

  .add-to-playlist-btn {
    margin: 0;
  }

  .big-stage-player .reactPlayer {
    min-height: 100% !important;
    height: 100% !important;
  }

  .mobile-video-details {
    height: 500px;
    overflow-y: auto;
  }

  .tranform-45 {
    transform: rotate(20deg);
  }

  .fs-16 {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 640px) {
  .fw-700 {
    font-weight: 500;
  }

  .episode-img-main {
    height: 40px;
    width: 40px;
  }

  .fs-sm-30 {
    font-size: 30px !important;
  }

  .video-thumb img {
    width: 100%;
    height: 250px;
  }

  .comment-main .container {
    padding: 0;
    overflow-x: hidden;
  }

  .more-newz {
    padding: 80px 0 0px 0;
  }

  .fs-16 {
    font-size: 15px !important;
  }

  .mobile-video {
    padding-top: 78px;
  }

  .custom-logo {
    height: 70px !important;
  }

  .mobile-view-scroll {
    height: 580px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .mobile-comment-list-scroll .modal {
    height: 620px;
    bottom: 0;
  }

  .tranform-45 {
    transform: rotate(20deg);
  }

  .mobile-video {
    background-color: rgb(18, 18, 22);

  }

  .mobile-video .card-body {
    background-color: #121216 !important;
  }

  .mobile-video-details {
    height: 500px;
    overflow-y: auto;
  }

  .video-thumb {
    position: relative;
  }

  .d-small-none {
    display: none;
  }

  .floating-input:focus~label,
  .floating-input:not(:placeholder-shown)~label {
    padding: 0px !important;
  }

  .nav-link {
    padding: 10px 5px !important;
  }

  .profile-main {
    margin-top: 10px;
  }

  .profile-box-right {
    font-size: 14px;
  }

  .mmt {
    margin-top: 10px !important;
  }

  .login-page {
    min-height: 640px !important;
  }

  .event-wrapText fs-40 fw-700 {
    margin: 370px 0px 0px 0px !important;
  }

  .feature-playlist-namne {
    font-size: 40px;
  }

  .login-area {
    padding: 30px 15px;
  }

  .title {
    font-size: 35px;
  }

  .option-text {
    font-size: 13px;
  }

  .Forget-page {
    padding: 100px 15px 100px 15px;
  }

  .login-sub-title {
    margin: 15px 0;
  }

  .create-an-account-page {
    padding: 30px 15px;
  }

  .form-row>.col,
  .form-row>[class*="col-"] {
    padding-right: 5px;
    /* padding-right: 25px; */
    padding-left: 5px;
  }

  .selection-page:nth-child(1) {
    margin-top: 0px;
    margin-bottom: 20px;
  }


  .cus-btn-width {
    min-width: 112px !important;
    max-width: 112px !important;
  }

  .album {
    padding: 30px 0;
  }

  .custom-play-icon {
    padding: 5px 5px !important;
  }


  .nav-link {
    padding: 10px 5px !important;
    text-align: center !important;
  }

  .cus-comment-sm {
    height: 0 !important;
  }

  .upload-section-singer-name {
    padding: 30px 0 0 0 !important;
  }


  .upload-section {
    padding: 0px !important;
  }

  .custom-play-icon {
    padding: 5px 5px !important;
  }



  .mt-15 {
    margin-left: 15px !important;
  }





  .big-stage-player .reactPlayer {
    min-height: 100% !important;
    height: 100% !important;
  }

  .mobile-list-time {
    position: absolute;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.616);
    color: white;
    padding: 3px 10px;
    font-size: 11px;

  }

  .custom-videoplayer-list {
    padding: 78px 0 0px 0 !important;
  }

  .mobile-comment-body {
    padding: 1rem;
    border-bottom: 1px solid #8080807a;
  }

  .web-container {
    padding: 0 15px !important;
  }
}

/*Mobile Device - Mdeia Query */
/*hitendra - mobile device*/

/* satyam  */
.dis-flex {
  display: flex;
}

.animat-pause {
  animation-play-state: paused;
}

.plusButton {
  /* added By Satyam */
  -webkit-backdrop-filter: blur(1px);
  /* backdrop-filter: blur(1px); */
  /* background: transparent !important; */
  top: 61px;
  left: -37px;
  position: absolute;
  padding: 6px 6px !important;
  border-radius: 80px !important;
  /* satyam end */
}

.webHeaderText {
  color: #b9b9b9;
  text-decoration: none !important;
  padding: 0px 10px;
  font-size: 14px;
}

.webHeaderText:hover {
  color: white !important;
  /* font-size: 16px !important; 
   font-weight: 500;  */
}

.activeLinkClass {
  color: white !important;
  font-size: 16px !important;
  font-weight: 500;
}

.playlist-bottom {
  position: absolute;
  bottom: 0;
}

.custom-videoplayer-list {
  padding: 130px 0 0px 0;
  background-color: #121216;
}

.featured-bio {
  max-width: 392px;
  text-align: justify;
}

.line {
  width: 100%;
  border: 1px solid #1d1f25;
  margin: 10px 0;
}

.big-stage-player .reactPlayer {
  min-height: 630px;
}


.big-stage-react-video .reactPlayer {
  min-height: 300px;
  max-height: 300px;
  width: 100%;
}

.main-player {
  border: 1px solid #222222;

  box-sizing: border-box;
}

.player-header {
  background-color: #000000;
  padding: 10px;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.text-gray-100 {
  color: rgb(230, 230, 230) !important;
}

.text-white-200 {
  color: rgb(168, 168, 168) !important;
}

.fs-30 {
  font-size: 30px;
}

.fs-14 {
  font-size: 14px;
  line-height: 20px;
}

.player-list-img {
  min-width: 77px;
  min-height: 60px;
  max-width: 77px;
  max-height: 60px;
  object-fit: cover;
  position: relative;
}

.player-list-time {
  position: absolute;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.616);
  color: white;
  padding: 0px 5px;
  font-size: 11px;

}

.player-list {
  padding: 15px 15px 15px 5px;

}

.main-list {
  height: 348px;
  overflow-y: auto;
}

.running-song {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 20px;
  color: #ffff;
}

.fs-20 {
  font-size: 20px;
}

.line-height-0 {
  line-height: 0;
}

.btn-outline-main {
  color: #ffd874 !important;
  background-color: transparent;
  border-color: #ffd874 !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  padding: 8px 15px !important;
  margin-top: 10px !important;
  font-size: 12px !important;
  min-width: 125px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-player-dialog .modal-content {
  padding: 0 !important;
  border-radius: 20px;
  height: 100%;
}

.video-player-dialog .btn-outline-main {
  min-width: 100% !important;
}

.video-player-dialog .btn-primary {
  min-width: 100% !important;
  justify-content: center;
}



.video-player-dialog .modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  margin: 0 !important;
}

.video-player-dialog .video-comment-list {
  padding: 0 16px;
}

hr {
  border-top: 0.3px solid #75757560 !important;
}

.video-player-dialog .modal-header {
  background-color: rgb(9 9 11) !important;
  border-radius: 20px 20px 0 0;
  border-bottom: 1px solid #8080807a !important;
}

.video-player-dialog .modal-body {
  background-color: rgb(9 9 11) !important;
  padding: 0;
}



.video-comments-sm {
  padding: 0 16px;
}

.text-light-gray {
  color: rgb(235, 235, 235);
}

.lh-20 {
  line-height: 25px;
}

.mobile-comment-lsist-scrool {
  height: 420px;
  overflow-y: auto;
}

.reactPlayer {
  object-fit: cover;
}

.reactPlayer .video {
  object-fit: cover;
}

video {
  object-fit: cover;
}

.mobile-comment-list-scroll .modal {
  height: 620px;
  bottom: 0;
}

.video-player-dialog .modal-dialog {
  width: 100%;
}

.video-player-dialog .modal-header {
  align-items: center !important;
}

.web-container {
  padding: 0;
}

.podcast-banner-2 .container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
}

.w-sm-50 {
  width: 50px;
  object-fit: cover !important;
  height: 50px;
}

.cursor-pointer {
  cursor: pointer;
}

.user-img {
  width: 130px;
  height: 130px;
  object-fit: cover;
}

.mt-30 {
  margin-top: 30px;
}

.br-100 {
  border-radius: 100%;
}

.mt-10 {
  margin-top: 10px !important;
}

.login-profile-upload .login-uplaod {
  position: relative;
}

.login-profile-upload .uploadIcon {
  display: none;
}

.login-profile-upload .plusButton {
  bottom: 0px;
  right: 0;
  top: auto;
  left: auto;
}

.comment-discription {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.banner-img {
  background-size: cover;
  background-repeat: no-repeat;
  height: 650px;
  padding: 0px 0;
  width: 100%;
}

.sec-episode {
  padding: 0 0 50px 0;
}

.episode-box {
  background-color: #181a20b0;
  padding: 20px 15px;
  margin-bottom: 10px;
}

.episode-img-main {
  height: 50px;
  width: 50px;
  border-radius: 5px;
  object-fit: cover;
}

.w-24 {
  width: 24px;
}

.comments-dot {
  color: #b09b2f;
  font-size: 19px;
  margin-right: 10px;
}

.sec-header {
  padding: 10px 15px;
}

.btn-pad-10 {
  margin: 0 10px;
}

.login-uplaod {
  margin-top: 30px;
}


.Playsorepopup {
  background-color: #121216;
  padding: 12px 12px;
  /* border-bottom: 1px solid #ffd874; */
  /* position: absolute;
  z-index: 1;
  top: 80px; */
  width: 100%;
}

.Playsorepopup .contain {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
}

.Playsorepopup .contain .malti-logo {
  /* border: 1px solid #ffd874; */
  margin-right: 14px;
}

.Playsorepopup .contain .malti-logo .multilogo {
  width: 50px;
  height: 50px;
}

.Playsorepopup .contain .text-contain .text-heading {
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  color: #fff;
}

.Playsorepopup .contain .text-contain .text-title {
  font-size: 11px;
  font-weight: 500;
  color: #e6e2ef;
}

.Install-Now {
  color: #000;
  background-image: linear-gradient(#ffd874, #d8a318);
  border-color: #ffb700;
  min-width: 120px;
  max-width: 120px;
  margin-top: 10px;
  font-size: 12px;
  padding: 10px;
}

/*Footer download app css*/
.footer-phone{
  padding: 5px 15px;
  background-color:#ffa5004f!important;
  bottom: 0px;
  position: fixed;
  width: 100%;

}

.footer-phone .footer-contain{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-phone .footer-contain .footer-text{
  font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 0px;
}
.footer-phone .footer-contain .footer-text-sub{
  font-size: 10px;
  font-weight: 500;
  color: #e6e2ef;
  margin-bottom: 0px;
}

.logofooter{
  width: 50px !important;
  height: 50px !important;
  border: 1px solid #d8a318;

}
.logofooter{
  width: 50px !important;
  height: 50px !important;

  border-radius: 6px;
}
.footer-aro{
  height: 35px;
  
    padding: 5px;
    border: 1px solid white;
    cursor: pointer;
}
.App-Store{
  width: 192px;
  background-repeat: no-repeat;
}
.Google-Store{
  width: 192px;
  background-repeat: no-repeat;
}
.app-store-width-set{
  width: 80%;
  margin: auto;
}
.button-footer-show{
  position: relative;
  bottom: 15px;
  right: 17px;
  /* width: 40px;
  height: 40px; */
  position: fixed;
  border: 1px solid #ffd874;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #121216;
  padding: 4px;
}