html {
    background-color: #121216;
}

.fs-25 {
    font-size: 25px;
}

.fs-20 {
    font-size: 20px;
}

.fs-23 {
    font-size: 23px;
}

.fs-15 {
    font-size: 15px;
    line-height: 25px;
}


.fs-40 {
    font-size: 40px;
}

.fs-18 {
    font-size: 18px;
}

.fs-14 {
    font-size: 14px;
}

.lh-20 {
    line-height: 25px;
}

.fs-16 {
    font-size: 16px;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.cts-dro-si .dropdown-toggle::after {
    display: none !important;
}

.text-yellow {
    color: rgb(176, 155, 47);
}

.color-light-dark {
    color: rgb(179, 179, 179);
}


.showmore-btn .btn {
    padding: 0 !important;
}