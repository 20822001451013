.emoji-img {
  font-size: 350px;

  text-align: center;
}

.emoji {
  padding: 100px 0 50px;
  background-color: black;
}

.page-not-found-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main-font {
  font-size: 130px;
  margin-top: 30px;
  letter-spacing: 10px;
}

.page-not-found {
  font-size: 30px;
}

.highlight {
  color: #fcc63c;
}

.error-text {
  font-size: 25px;
  text-align: center;
  margin-top: 30px;
  line-height: 35px;
}

.w-200 {
  width: 200px;
}